import React from 'react';
import './App.css';
import PlaceHolder from './Components/PlaceHolder/'


function App() {
  return (
    <div className="App">
      <PlaceHolder/>

    </div>
  );
}

export default App;
