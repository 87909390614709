import React from 'react'
import {ShiftDragon, Logo, SpotifyLogo} from '../svgs'
import styled from 'styled-components'

const Wrapper = styled.div`
background-image:url(${ShiftDragon});
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 800px;
    margin: 0 auto;
    padding:16px;
    box-sizing:border-box;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Body = styled.div`
background: rgba(24,23,23,0.95);
border-radius:8px;
padding:16px;
box-sizing:border-box;
color:white;
justify-content: center;
max-width:320px;
 p{
    font-family: 'Roboto Condensed', sans-serif;
    font-size:16px;
    line-height:32px;
}
`
const LogoWrap = styled.img`
width: 200px;
display:block;
margin:0 auto;
`

const ListenButton = styled.a`
background-color: white;
width: 100%;
height: 64px;
display: flex;
border-radius: 8px;
padding: 16px;
box-sizing: border-box;
justify-content: center;

img{
    height:100%;
    margin:0 auto;
}
`

const Message = styled.div`
max-width:400px;
margin:0 auto;
p{
    font-family: 'Roboto Condensed', sans-serif;
    font-size:40px;
    line-height:16px;
    color:white;
    font-variant: small-caps;
}

`

export default function PlaceHolder(){
return(
    <Wrapper>
       <Body>
        <LogoWrap src={Logo}/>
       <p>A Dungeons and Dragons podcast that rolls to see who DMs each session?</p>
       <p>Sounds like madness. And it is... Join us!</p> 
       <ListenButton href="https://open.spotify.com/show/2r8LQSNUEQupWOZ564JofQ?si=sFy0PBz7QJ-na6jf3ZV8ew"><img src={SpotifyLogo}/></ListenButton>
       </Body>
       <Message>
        <p>website coming soon</p>
        <p>...hopefully</p>
      </Message>
    </Wrapper>
)}